import { render, staticRenderFns } from "./MyPageParent.vue?vue&type=template&id=4055c8b5&scoped=true"
import script from "./MyPageParent.vue?vue&type=script&lang=ts"
export * from "./MyPageParent.vue?vue&type=script&lang=ts"
import style0 from "./MyPageParent.vue?vue&type=style&index=0&id=4055c8b5&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4055c8b5",
  null
  
)

export default component.exports