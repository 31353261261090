<template>
  <div>
    <base-information :formatStudentInfor="studentInfor"></base-information>
    <section class="section-wrap section-news mb-0">
      <div class="section-head">
        <h2 class="section-title">アクティビティ</h2>
      </div>
      <div class="section-content">
        <p>1週間のスケジュール（行事やイベントは、Slackでお知らせするよ）</p>
      </div>
      <div class="column-content text-center image-schedule">
        <img :src="imageSchedule" alt="metalife" />
      </div>
    </section>
    <section class="section-wrap section-news">
      <div class="section-head">
        <h2 class="section-title">参加ツール</h2>
      </div>
      <div class="section-content ml-16 metalife-zoom">
        <div class="row mb-10">
          <div class="col-6">
            <h1 class="section-content__title">Metalife</h1>
            <div class="section-content__detail">
              <p>
                SOZOWスクールのキャンパスです。<br />
                探Qやレクやガクサポなど色んなことが楽しめるよ。
              </p>
            </div>
            <div class="mt-5 section-content__btn">
              <button-link
                :text="'MetaLifeへGO'"
                :icon="iconButton"
                :url="urlMetaLife"
                @click="saveLogActionPage(metaLifeButton)"
              ></button-link>
            </div>
          </div>
          <div class="col-6 text-center image-metalife">
            <img :src="imageMetalife" alt="schedule" />
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <h1 class="section-content__title">Zoom</h1>
            <p class="section-content__detail">
              スタッフが色んなテーマで話すホームルームはZoomを使って参加できるよ。
            </p>
            <div class="mt-5 section-content__btn">
              <button-link
                :text="'ZoomへGO'"
                :icon="iconButton"
                :url="urlZoom"
                @click="saveLogActionPage(zoomButton)"
              ></button-link>
            </div>
          </div>
          <div class="col-6 text-center image-zoom">
            <img :src="imageZoom" alt="zoom" />
          </div>
        </div>
      </div>
    </section>

    <!-- メモ：活動記録の公開範囲が決定、認識が揃っていないため非表示 -->
    <!--    <section class="section-wrap section-news">-->
    <!--      <div class="section-head">-->
    <!--        <h2 class="section-title">活動記録</h2>-->
    <!--      </div>-->
    <!--      <div class="section-content mb-3">-->
    <!--        <iframe-->
    <!--          width="100%"-->
    <!--          height="750"-->
    <!--          :src="urlIframe"-->
    <!--          frameborder="0"-->
    <!--          style="border: 0"-->
    <!--          allowfullscreen-->
    <!--          sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"-->
    <!--        ></iframe>-->
    <!--      </div>-->
    <!--    </section>-->

    <contact style="margin-top: 20%"></contact>
  </div>
</template>
<script lang="ts">
/* eslint-disable */
import Vue from "vue";
import iconArrowGoRight from "@/assets/images/common/arrow-go-right.svg";
import BaseInformation from "./components/BaseInformation.vue";
import Contact from "./components/Contact.vue";
import ButtonLink from "@/components/common/ButtonLink.vue";
import imageMetalife from "@/assets/images/my_page/metalife.png";
import imageZoom from "@/assets/images/my_page/zoom.png";
import sukitanqSchedule from "@/assets/images/my_page/sukitanq_schedule.png";
import zibuntanqSchedule from "@/assets/images/my_page/zibuntanq_schedule.png";
import miraitanqSchedule from "@/assets/images/my_page/miraitanq_schedule.png";
import { mapState } from "vuex";
import * as constants from "@/constants";
import { store } from "@/services/logs";
import { b64DecodeUnicode } from "@/services/helper";

export default Vue.extend({
  components: {
    BaseInformation,
    Contact,
    ButtonLink,
  },
  computed: {
    ...mapState({
      user: (state: any) => state.user,
    }),
  },
  data() {
    return {
      isEnrollment: false,
      iconButton: iconArrowGoRight,
      imageMetalife: imageMetalife,
      imageZoom: imageZoom,
      imageSchedule: "",
      studentInfor: {},
      metaLifeButton: "metalife_button",
      urlMetaLife: constants.URL_META_LIFE,
      zoomButton: "zoom_button",
      urlZoom: constants.URL_ZOOM,
      urlIframe: "",
    };
  },
  mounted() {
    if (this.user.role == constants.ROLES.STUDENT) {
      this.studentInfor = {
        userName: this.user.user_name,
        nickName: this.user.nickname,
        email: this.user.email,
        admissionDate: this.user.admission_season,
        classGuide: this.user.class_guide,
        mentor: this.user.name_staff,
        course: this.user.current_plan,
        school: this.user.member_id,
      };
    }
    if (this.user?.current_plan?.includes("未来探Q")) {
      this.imageSchedule = miraitanqSchedule;
    } else if (this.user?.current_plan?.includes("自分探Q")) {
      this.imageSchedule = zibuntanqSchedule;
    } else if (this.user?.current_plan?.includes("好き探Q")) {
      this.imageSchedule = sukitanqSchedule;
    }

    const baseIframe = localStorage.getItem("BASE_IFRAME");

    if (baseIframe) {
      const params = {
        passwordForCoinCredit: baseIframe,
        passwordFor30sec: baseIframe,
        passwordForCoinLogs: baseIframe,
        passwordForReportCommentSummary: baseIframe,
        passwordForSlackVotes: baseIframe,
        passwordForMetalife: baseIframe,
      };

      const paramString = `?params=${encodeURIComponent(
        JSON.stringify(params)
      )}`;

      this.urlIframe = `${constants.URL_IFRAME}${paramString}`;
    }
  },
  methods: {
    async saveLogActionPage(element: string) {
      const userAgent = navigator.userAgent.toLowerCase();

      let targetDevice = "";
      if (
        userAgent.includes("mobile") ||
        userAgent.includes("android") ||
        userAgent.includes("iphone") ||
        userAgent.includes("ipad") ||
        userAgent.includes("version")
      ) {
        targetDevice = "SP"; // Smartphone
      } else {
        targetDevice = "PC"; // Personal Computer
      }
      let formData: object = {
        user_id: this.user.user_id,
        operated_school_id: this.user.member_id,
        screen_name: "children_mypage",
        element_name: element,
        action: "tap",
        target_device: targetDevice,
      };

      await store(formData)
        .then((data: any) => {
          console.log(data.data);
        })
        .catch((e: any) => {
          console.log("save log fail!");
        });
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/assets/scss/pages/mypage";
</style>
